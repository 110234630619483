<template>
  <v-footer
    bottom
    style="
      border-top: 2px solid rgba(0, 0, 0, 0.15);
      background: white;
      position: relative;
      bottom: 0px;
      width: -webkit-fill-available;
    "
  >
    <v-col cols="12">
      <v-row justify="start" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="padding-bottom: 32px;">
          <v-row justify="start" no-gutters>
            <LogoIcon
              style="margin-left: -8px; margin-right: 8px; max-width: 54px"
            />
            <LogoText />
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="padding-bottom: 32px;">
          <v-row justify="start">
            <v-icon class="socialLink" large> mdi-facebook </v-icon>
            <v-icon class="socialLink" large> mdi-twitter </v-icon>
            <v-icon class="socialLink" large> mdi-linkedin </v-icon>
            <v-icon class="socialLink" large> mdi-youtube </v-icon>
          </v-row>
        </v-col>
        <v-col cols="3" xs="3" sm="3" md="3" lg="2" xl="2">
          <v-row class="footerTitle" justify="start" no-gutters>{{
            $t("footer.product")
          }}</v-row>
          <router-link class="link" :to="{ name: 'Features' }">
            <v-row class="footerLink" justify="start" no-gutters>{{
              $t("footer.features")
            }}</v-row></router-link
          >
          <router-link class="link" :to="{ name: 'Benefits' }">
            <v-row class="footerLink" justify="start" no-gutters>{{
              $t("footer.benefits")
            }}</v-row></router-link
          >
          <router-link class="link" :to="{ name: 'Pricing' }">
            <v-row class="footerLink" justify="start" no-gutters>{{
              $t("footer.pricing")
            }}</v-row>
          </router-link>
          <router-link class="link" :to="{ name: 'Security' }">
            <v-row class="footerLink" justify="start" no-gutters>{{
              $t("footer.security")
            }}</v-row>
          </router-link>
        </v-col>
        <v-col cols="3" xs="3" sm="3" md="3" lg="2" xl="2">
          <v-row class="footerTitle" justify="start" no-gutters>TENTIFLY</v-row>
          <router-link class="link" :to="{ name: 'Contact' }">
            <v-row class="footerLink" justify="start" no-gutters>{{
              $t("footer.contacUs")
            }}</v-row></router-link
          >
          <router-link class="link" :to="{ name: 'Contact' }">
            <v-row class="footerLink" justify="start" no-gutters>{{
              $t("footer.becomePartner")
            }}</v-row>
          </router-link>
        </v-col>
        <v-col cols="3" xs="3" sm="3" md="3" lg="2" xl="2">
          <v-row class="footerTitle" justify="start" no-gutters>{{
            $t("footer.legal")
          }}</v-row>
          <v-row class="footerLink" justify="start" no-gutters>{{
            $t("footer.gdpr")
          }}</v-row>
          <router-link class="link" :to="{ name: 'Privacy policy' }">
            <v-row class="footerLink" justify="start" no-gutters>{{
              $t("footer.cookiesAndWebpage")
            }}</v-row>
          </router-link>
          <v-row class="footerLink" justify="start" no-gutters>{{
            $t("footer.termsAndConditions")
          }}</v-row>
          <router-link class="link" :to="{ name: 'Privacy policy' }">
            <v-row class="footerLink" justify="start" no-gutters>{{
              $t("footer.privacyPolicy")
            }}</v-row>
          </router-link>
          <v-row class="footerLink" justify="start" no-gutters>{{
            $t("footer.dataProcessingAgreement")
          }}</v-row>
          <router-link class="link" :to="{ name: 'Sub-processors' }">
            <v-row class="footerLink" justify="start" no-gutters>{{
              $t("footer.subProcessors")
            }}</v-row></router-link
          >
        </v-col>
        <v-col cols="3" xs="3" sm="3" md="3" lg="2" xl="2">
          <v-row class="footerTitle" justify="start" no-gutters>{{
            $t("footer.resource")
          }}</v-row>
          <router-link class="link" :to="{ name: 'Contact' }">
            <v-row class="footerLink" justify="start" no-gutters>{{
              $t("footer.support")
            }}</v-row>
          </router-link>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row class="footerLink" justify="start" no-gutters>
        <v-col> © {{ new Date().getFullYear() }} Tentifly OÜ</v-col>
      </v-row>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",
  components: {
    LogoText: () => import("@/components/LogoText.vue"),
    LogoIcon: () => import("@/components/LogoIcon.vue"),
  },
  data: () => ({
    links: ["Privacy policy", "Security", "Sub-processors"],
    social: [
      //'Facebook',
      //'Twitter',
      //'Instagram',
      //'Linkedin', border-top: 1px solid #d5d5d5; color: grey; background-color: white!important;
    ],
  }),
};
</script>

<style scoped>
@media screen and (max-device-width: 360px) {
  .trademark {
    display: none;
  }
}
.link {
  text-decoration: none;
}

.socialLink:hover {
  opacity: 1;
}

.socialLink {
  opacity: 0.5;
  padding: 4px;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}
.footerTitle {
  margin-bottom: 15px;
  color: #2d3958;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.footerLink {
  margin-top: 3px;
  margin-bottom: 3px;
  padding-top: 8px;
  padding-right: 6px;
  padding-bottom: 8px;
  color: #626f8f;
  font-size: 16px;
  line-height: 1.2;
  -text-align: left;
  text-decoration: none;
}
.footerText {
  padding-left: 8px;
  padding-right: 8px;
  font-weight: bold;
  color: white;
}
</style>
